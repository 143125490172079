

export const environment = {
  production: false,
  baseUrl: "https://apihrms.kanhaiya.co.in/api/index.php/",
  mediaURL: 'https://apihrms.kanhaiya.co.in/api/uploads/',
  authToken: 'kanhaiya',
  firebase: {
    apiKey: "AIzaSyDxLYCop7kgMKrMCjlnrlJBJWcXB1lByTU",
    authDomain: "chatapp-5e797.firebaseapp.com",
    projectId: "chatapp-5e797",
    storageBucket: "chatapp-5e797.appspot.com",
    messagingSenderId: "409361595207",
    appId: "1:409361595207:web:48ef145edd4291b956ea62"
  },
  apiUrls: {

    apis: {

      savelog: 'logs/save',
      getlogs:'logs/getAll',

      Login: 'employee/loginUser',
      getParameterList: 'parameter/get_all',
      updateSettings:'parameter/editParameter',

      saveusers: 'users/registerUser',
      updateusers: 'employee/editList',
      getusers: 'employee/getById',
      users:'employee/get_all',
      departmentlist:'department/getAll',

      assetList: 'asset/get_all',
      saveasset: 'asset/save',
      updateasset: 'asset/editList',
      deleteeasset: 'asset/deleteList',
      getasset: 'asset/getById',
      getByassetNo: 'asset/getByassetNo',
      getassetReport: 'asset/getReport',
      getassetBySupplier: 'asset/getassetBySupplier',
      saveassetBankAccount: 'asset/savebankaccount',
      deleteassetBankAccount: 'asset/deletebankaccount',
      getassetamount: 'asset/getassetamount',
      
      assetrequestList: 'assetrequest/get_all',
      saveassetrequest: 'assetrequest/save',
      updateassetrequest: 'assetrequest/editList',
      deleteeassetrequest: 'assetrequest/deleteList',
      getassetrequest: 'assetrequest/getById',
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
