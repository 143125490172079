import { Component, OnInit } from '@angular/core';
import { Router,NavigationExtras } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  name: any = '';
  mediaURL: any = '';

  options=['New Trip','Trip List','Trip Created','Truck Placed','Truck Released','Payment Updated','Complete'];
  optionsoperator=['Trip List','Trip Created','Truck Placed','Truck Released','Payment Updated','Complete'];
  image:any='';



  role:any='';

  constructor(
    private router: Router,
    private service: AuthService,
    private _auth:AuthenticationService
    ) { 
      this.mediaURL = environment.mediaURL
      this.name = localStorage.getItem('name');
      this.role= localStorage.getItem('role');
    }

  ngOnInit() {
    
    this.mediaURL = environment.mediaURL;
    this.name = localStorage.getItem('name');
    this.role= localStorage.getItem('role');
  
  }

  selectitem(item:any)
  {
    if(item=='New Trip')
    {
      this.router.navigate(['home-page/directconsignment/'] );
    }
    else if(item=='Trip List')
    {
      this.router.navigate(['home-page/directconsignmentall/'] );
    }
    else 
    {  
      const navData: NavigationExtras = {
        queryParams: {
          stage: item
        }
      };
      this.router.navigate(['home-page/directconsignmentall/'], navData);

    }
    
  }

  openusers()
  {
    this.router.navigate(['consignmentmenu-page/userall/']);
  }

  opentrips()
  {
    this.router.navigate(['consignmentmenu-page/directconsignmentall/']);
  }

  ionViewDidEnter() {
    this.mediaURL = environment.mediaURL;
    this.name = localStorage.getItem('name');
    this.role= localStorage.getItem('role');
  }

  logout(){
    localStorage.clear()
    this._auth.logout();
    this.router.navigate(['login']);

  }

  redirect(path) {
    this.router.navigate([path]);
  }
 


}
